import React, { useEffect, useState } from 'react';
import { CrmSearchField } from './Results/CrmSearchField';
import { CrmList } from './Results/CrmList';
import { CrmFacetsList } from './Results/CrmFacetsList';
import { countryName } from '../../../utils/formatter';
import { CrmActions } from './Results/CrmActions';
export function CrmSearchEngine(props) {
    const [results, setResults] = useState(null);
    const [resultsLoading, setResultsLoading] = useState(false);
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const [moreResultsLoading, setMoreResultsLoading] = useState(false);
    const [facets, setFacets] = useState(null);
    const [openedResults, setOpenedResults] = useState([]);
    const [nbHits, setNbHits] = useState(null);
    const refreshResults = () => {
        setResultsLoading(true);
        props.searchEngine.refreshResults().then((response) => {
            setResultsLoading(false);
            setResults(response.hits);
            setNbHits(response.nbHits);
            setFacets(response.facets);
            setHasMoreResults(response.hasMoreResults);
        });
    };
    // Initial results
    useEffect(() => refreshResults(), []);
    // Handle loading more
    const handleLoadMoreClick = () => {
        setMoreResultsLoading(true);
        props.searchEngine.loadMore().then((response) => {
            setResults(response.hits);
            setNbHits(response.nbHits);
            setFacets(response.facets);
            setMoreResultsLoading(false);
            setHasMoreResults(response.hasMoreResults);
        });
    };
    // Handle items details
    const handleItemClick = (item) => {
        if (openedResults.indexOf(item.id) > -1) {
            setOpenedResults(openedResults.filter((i) => i !== item.id));
        }
        else {
            setOpenedResults(openedResults.concat([item.id]));
        }
    };
    const facetsView = props.searchEngine.getFacetsView();
    const facetsFormatters = {
        tags_names: (v) => v,
        projects: (v) => (props.facetsRegistries ? props.facetsRegistries.projectsNames[v] || '' : v),
        status: (v) => props.itemLabels.status[v],
        profile_company: (v) => v,
        area_country_code: (v) => countryName(v),
        area_province_name: (v) => v,
        area_district_name: (v) => v,
    };
    return (React.createElement("div", { className: "crm-search", role: resultsLoading ? 'crm-loading' : 'crm-ready' },
        React.createElement(CrmSearchField, { key: props.searchEngine.getQuery(), value: props.searchEngine.getQuery(), isQueryLanguage: props.searchEngine.isQueryLanguage(), facetsView: facetsView, facetsFormatters: facetsFormatters, showProjectFacet: !props.isProjectView, labels: props.searchFieldLabels, onQueryChange: (query) => {
                props.searchEngine.setQuery(query);
                refreshResults();
            }, onModeChange: (isQueryLanguage) => {
                props.searchEngine.setMode(isQueryLanguage);
                refreshResults();
            } }),
        React.createElement("div", { className: "crm-search-results" },
            React.createElement("div", { className: "crm-search-results-list" },
                React.createElement(CrmList, Object.assign({ results: results, resultsLoading: resultsLoading, sortField: props.searchEngine.getSortField(), sortType: props.searchEngine.getSortType(), onSortChange: (field, type) => {
                        props.searchEngine.setSort(field, type);
                        refreshResults();
                    }, openedItems: openedResults, hasMoreResults: hasMoreResults, onItemClick: handleItemClick, onLoadMoreClick: handleLoadMoreClick, isLoadingMore: moreResultsLoading }, props))),
            React.createElement("div", { className: "crm-search-results-side" },
                React.createElement("div", { className: "crm-search-results-actions" },
                    React.createElement(CrmActions, { nbHits: nbHits, batch: props.batch, createBatchPayload: () => props.searchEngine.getBatchPayload(), tagsRegistry: props.tagsRegistry, actionsLabels: props.actionsLabels, onActionFinished: () => {
                            refreshResults();
                        }, onResetClick: () => {
                            props.searchEngine.resetFilters();
                            refreshResults();
                        } })),
                Object.keys(facetsView).length > 0 ? (React.createElement("div", { className: "crm-search-results-facets" },
                    React.createElement(CrmFacetsList, { facets: facets, facetsView: facetsView, facetsFormatters: facetsFormatters, showProjectFacet: !props.isProjectView, facetsLabels: props.facetsLabels, onFilterInclude: (facetName, value) => {
                            props.searchEngine.includeFilter(facetName, value);
                            refreshResults();
                        }, onFilterExclude: (facetName, value) => {
                            props.searchEngine.excludeFilter(facetName, value);
                            refreshResults();
                        }, onFilterCancel: (facetName, value) => {
                            props.searchEngine.cancelFilter(facetName, value);
                            refreshResults();
                        } }))) : ('')))));
}
