import React from 'react';
import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { CrmFacetSelector } from './CrmFacetSelector';
export function CrmFacetsList(props) {
    if (!props.facetsView) {
        return React.createElement(Spinner, { size: SpinnerSize.SMALL });
    }
    let facetsList = [
        'tags_names',
        'status',
        'profile_company',
        'area_country_code',
        'area_province_name',
        'area_district_name',
    ];
    if (props.showProjectFacet) {
        facetsList.push('projects');
    }
    return (React.createElement(React.Fragment, null, facetsList.map((facetName) => {
        const values = props.facetsView[facetName] || null;
        if (!values || values.length === 0) {
            return React.createElement("div", { key: facetName });
        }
        return (React.createElement(CrmFacetSelector, { key: facetName, facet: facetName, values: values, formatValue: props.facetsFormatters[facetName], onFilterInclude: (value) => props.onFilterInclude(facetName, value), onFilterExclude: (value) => props.onFilterExclude(facetName, value), onFilterCancel: (value) => props.onFilterCancel(facetName, value), facetsLabels: props.facetsLabels }));
    })));
}
